.introjs-overlay {
  position: absolute;
  box-sizing: content-box;
  z-index: 999999;
  background-color: #000;
  opacity: 0;
  background: radial-gradient(center,ellipse farthest-corner,rgba(0,0,0,0.4) 0,rgba(0,0,0,0.9) 100%);
  filter: "progid:DXImageTransform.Microsoft.gradient(startColorstr='#66000000',endColorstr='#e6000000',GradientType=1)";
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=50)";
  filter: alpha(opacity=50);
  transition: all 0.3s ease-out;
}

.introjs-fixParent {
  z-index: auto !important;
  opacity: 1.0 !important;
  transform: none !important;
}

.introjs-showElement,
tr.introjs-showElement > td,
tr.introjs-showElement > th {
  z-index: 9999999 !important;
}

.introjs-disableInteraction {
  z-index: 99999999 !important;
  position: absolute;
  background-color: white;
  opacity: 0;
  filter: alpha(opacity=0);
}

.introjs-relativePosition,
tr.introjs-showElement > td,
tr.introjs-showElement > th {
  position: relative;
}

.introjs-helperLayer {
  box-sizing: content-box;
  position: absolute;
  z-index: 9999998;
  background-color: #FFF;
  background-color: rgba(255,255,255,.9);
  border: 1px solid #777;
  border: 1px solid rgba(0,0,0,.5);
  border-radius: 4px;
  box-shadow: 0 2px 15px rgba(0,0,0,.4);
  transition: all 0.3s ease-out;
}

.introjs-tooltipReferenceLayer {
  box-sizing: content-box;
  position: absolute;
  visibility: hidden;
  z-index: 100000000;
  background-color: transparent;
  transition: all 0.3s ease-out;
}

.introjs-helperLayer *,
.introjs-helperLayer *:before,
.introjs-helperLayer *:after {
  -ms-box-sizing: content-box;
   -o-box-sizing: content-box;
      box-sizing: content-box;
}

.introjs-helperNumberLayer {
  box-sizing: content-box;
  position: absolute;
  visibility: visible;
  top: -16px;
  left: -16px;
  z-index: 9999999999 !important;
  padding: 2px;
  font-family: Arial, verdana, tahoma;
  font-size: 13px;
  font-weight: bold;
  color: white;
  text-align: center;
  text-shadow: 1px 1px 1px rgba(0,0,0,.3);
  background: #ff3019; /* Old browsers */ /* Chrome10+,Safari5.1+ */ /* Chrome,Safari4+ */ /* FF3.6+ */ /* IE10+ */ /* Opera 11.10+ */
  background:         linear-gradient(to bottom, #ff3019 0%, #cf0404 100%);  /* W3C */
  width: 20px;
  height:20px;
  line-height: 20px;
  border: 3px solid white;
  border-radius: 50%;
  filter: "progid:DXImageTransform.Microsoft.gradient(startColorstr='#ff3019', endColorstr='#cf0404', GradientType=0)"; /* IE6-9 */
  filter: "progid:DXImageTransform.Microsoft.Shadow(direction=135, strength=2, color=ff0000)"; /* IE10 text shadows */
  box-shadow: 0 2px 5px rgba(0,0,0,.4);
}

.introjs-arrow {
  border: 5px solid transparent;
  content:'';
  position: absolute;
}
.introjs-arrow.top {
  top: -10px;
  border-bottom-color:white;
}
.introjs-arrow.top-right {
  top: -10px;
  right: 10px;
  border-bottom-color:white;
}
.introjs-arrow.top-middle {
  top: -10px;
  left: 50%;
  margin-left: -5px;
  border-bottom-color:white;
}
.introjs-arrow.right {
  right: -10px;
  top: 10px;
  border-left-color:white;
}
.introjs-arrow.right-bottom {
  bottom:10px;
  right: -10px;
  border-left-color:white;
}
.introjs-arrow.bottom {
  bottom: -10px;
  border-top-color:white;
}
.introjs-arrow.bottom-right {
  bottom: -10px;
  right: 10px;
  border-top-color:white;
}
.introjs-arrow.bottom-middle {
  bottom: -10px;
  left: 50%;
  margin-left: -5px;
  border-top-color:white;
}
.introjs-arrow.left {
  left: -10px;
  top: 10px;
  border-right-color:white;
}
.introjs-arrow.left-bottom {
  left: -10px;
  bottom:10px;
  border-right-color:white;
}

.introjs-tooltip {
  box-sizing: content-box;
  position: absolute;
  visibility: visible;
  padding: 10px;
  background-color: white;
  min-width: 200px;
  max-width: 300px;
  border-radius: 3px;
  box-shadow: 0 1px 10px rgba(0,0,0,.4);
  transition: opacity 0.1s ease-out;
}

.introjs-tooltipbuttons {
  text-align: right;
  white-space: nowrap;
}

/*
 Buttons style by http://nicolasgallagher.com/lab/css3-github-buttons/
 Changed by Afshin Mehrabani
*/
.introjs-button {
  box-sizing: content-box;
  position: relative;
  overflow: visible;
  display: inline-block;
  padding: 0.3em 0.8em;
  border: 1px solid #d4d4d4;
  margin: 0;
  text-decoration: none;
  text-shadow: 1px 1px 0 #fff;
  font: 11px/normal sans-serif;
  color: #333;
  white-space: nowrap;
  cursor: pointer;
  outline: none;
  background-color: #ececec;
  background-image: linear-gradient(#f4f4f4, #ececec);
  -webkit-background-clip: padding;
  -moz-background-clip: padding;
  -o-background-clip: padding-box;
  /*background-clip: padding-box;*/ /* commented out due to Opera 11.10 bug */
  border-radius: 0.2em;
  /* IE hacks */
  zoom: 1;
  *display: inline;
  margin-top: 10px;
}

.introjs-button:hover {
  border-color: #bcbcbc;
  text-decoration: none;
  box-shadow: 0px 1px 1px #e3e3e3;
}

.introjs-button:focus,
.introjs-button:active {
  background-image: linear-gradient(#ececec, #f4f4f4);
}

/* overrides extra padding on button elements in Firefox */
.introjs-button::-moz-focus-inner {
  padding: 0;
  border: 0;
}

.introjs-skipbutton {
  box-sizing: content-box;
  margin-right: 5px;
  color: #7a7a7a;
}

.introjs-prevbutton {
  border-radius: 0.2em 0 0 0.2em;
  border-right: none;
}

.introjs-prevbutton.introjs-fullbutton {
  border: 1px solid #d4d4d4;
  border-radius: 0.2em;
}

.introjs-nextbutton {
  border-radius: 0 0.2em 0.2em 0;
}

.introjs-nextbutton.introjs-fullbutton {
  border-radius: 0.2em;
}

.introjs-disabled, .introjs-disabled:hover, .introjs-disabled:focus {
  color: #9a9a9a;
  border-color: #d4d4d4;
  box-shadow: none;
  cursor: default;
  background-color: #f4f4f4;
  background-image: none;
  text-decoration: none;
}

.introjs-hidden {
     display: none;
}

.introjs-bullets {
  text-align: center;
}
.introjs-bullets ul {
  box-sizing: content-box;
  clear: both;
  margin: 15px auto 0;
  padding: 0;
  display: inline-block;
}
.introjs-bullets ul li {
  box-sizing: content-box;
  list-style: none;
  float: left;
  margin: 0 2px;
}
.introjs-bullets ul li a {
  box-sizing: content-box;
  display: block;
  width: 6px;
  height: 6px;
  background: #ccc;
  border-radius: 10px;
  -moz-border-radius: 10px;
  -webkit-border-radius: 10px;
  text-decoration: none;
  cursor: pointer;
}
.introjs-bullets ul li a:hover {
  background: #999;
}
.introjs-bullets ul li a.active {
  background: #999;
}

.introjs-progress {
  box-sizing: content-box;
  overflow: hidden;
  height: 10px;
  margin: 10px 0 5px 0;
  border-radius: 4px;
  background-color: #ecf0f1
}
.introjs-progressbar {
  box-sizing: content-box;
  float: left;
  width: 0%;
  height: 100%;
  font-size: 10px;
  line-height: 10px;
  text-align: center;
  background-color: #08c;
}

.introjsFloatingElement {
  position: absolute;
  height: 0;
  width: 0;
  left: 50%;
  top: 50%;
}

.introjs-fixedTooltip {
  position: fixed;
}

.introjs-hint {
  box-sizing: content-box;
  position: absolute;
  background: transparent;
  width: 20px;
  height: 15px;
  cursor: pointer;
}
.introjs-hint:focus {
    border: 0;
    outline: 0;
}
.introjs-hidehint {
  display: none;
}

.introjs-fixedhint {
  position: fixed;
}

.introjs-hint:hover > .introjs-hint-pulse {
  border: 5px solid rgba(60, 60, 60, 0.57);
}

.introjs-hint-pulse {
  box-sizing: content-box;
  width: 10px;
  height: 10px;
  border: 5px solid rgba(60, 60, 60, 0.27);
  border-radius: 30px;
  background-color: rgba(136, 136, 136, 0.24);
  z-index: 10;
  position: absolute;
  transition: all 0.2s ease-out;
}
.introjs-hint-no-anim .introjs-hint-dot {
  -webkit-animation: none;
  animation: none;
}
.introjs-hint-dot {
  box-sizing: content-box;
  border: 10px solid rgba(146, 146, 146, 0.36);
  background: transparent;
  border-radius: 60px;
  height: 50px;
  width: 50px;
  -webkit-animation: introjspulse 3s ease-out;
  animation: introjspulse 3s ease-out;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  position: absolute;
  top: -25px;
  left: -25px;
  z-index: 1;
  opacity: 0;
}

@-webkit-keyframes introjspulse {
    0% {
        -webkit-transform: scale(0);
        opacity: 0.0;
    }
    25% {
        -webkit-transform: scale(0);
        opacity: 0.1;
    }
    50% {
        -webkit-transform: scale(0.1);
        opacity: 0.3;
    }
    75% {
        -webkit-transform: scale(0.5);
        opacity: 0.5;
    }
    100% {
        -webkit-transform: scale(1);
        opacity: 0.0;
    }
}

@keyframes introjspulse {
    0% {
        transform: scale(0);
        opacity: 0.0;
    }
    25% {
        transform: scale(0);
        opacity: 0.1;
    }
    50% {
        transform: scale(0.1);
        opacity: 0.3;
    }
    75% {
        transform: scale(0.5);
        opacity: 0.5;
    }
    100% {
        transform: scale(1);
        opacity: 0.0;
    }
}

.introjs-tooltipbuttons{text-align:left}.introjs-skipbutton{margin-left:5px}.introjs-tooltip{direction:rtl}.introjs-prevbutton{border:1px solid #d4d4d4;border-left:none;border-radius:0 .2em .2em 0}.introjs-nextbutton{border:1px solid #d4d4d4;border-radius:.2em 0 0 .2em}.introjs-bullets ul li{float:right}

.DatePicker {
  position: relative;
  display: inline-block;
  z-index: 100;
}

.DatePicker__input {
  background: #fff;
  border: 1px solid #ddd;
  padding: 0.4em 0.8em;
  font-family: inherit;
  text-align: center;
  font-size: 12px;
}

.DatePicker__input.-rtl {
  direction: rtl;
}

.DatePicker__input::-webkit-input-placeholder {
  color: #979797;
}

.DatePicker__input:-ms-input-placeholder {
  color: #979797;
}

.DatePicker__input::-ms-input-placeholder {
  color: #979797;
}

.DatePicker__input::placeholder {
  color: #979797;
}

.DatePicker__calendarContainer.-top + .DatePicker__calendarArrow {
  top: auto;
  bottom: calc(100% + 10px);
  transform: translateY(-2.5rem) rotate(180deg);
  -webkit-animation: fadeArrowFlipped 0.3s forwards;
          animation: fadeArrowFlipped 0.3s forwards;
}

.DatePicker__calendarContainer {
  position: absolute;
  top: calc(100% + 20px);
  left: 50%;
  transform: translateX(-50%);
}

.DatePicker__calendarContainer.-top {
  top: auto;
  bottom: calc(100% + 20px);
}

.Calendar,
.Calendar * {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  direction: ltr;
}

.Calendar,
.Calendar.-rtl * {
  direction: rtl;
}

.DatePicker__calendarArrow {
  position: absolute;
  width: 0;
  height: 0;
  top: calc(100% + 10px);
  left: 0;
  right: 0;
  margin: 0 auto;
  border-style: solid;
  z-index: 10;
  border-width: 0 10px 10px 10px;
  border-color: transparent transparent #fff transparent;
}

.Calendar {
  --cl-color-black: #444444;
  --cl-color-disabled: #d4d4d4;
  --cl-color-error: #ff2929;
  font-size: 10px;
  background: #fff;
  box-shadow: 0 1em 4em rgba(0, 0, 0, 0.07);
  border-radius: 1em;
  position: relative;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
  padding-top: 1.2em;
  display: flex;
  flex-direction: column;
  width: 33em;
  z-index: 10;
  max-width: 90vw;
  min-height: 36.7em;
}

.DatePicker .Calendar,
.DatePicker__calendarArrow {
  transform: translateY(2.5em);
  opacity: 0;
  -webkit-animation: fadeCalendar 0.3s forwards;
          animation: fadeCalendar 0.3s forwards;
}

.DatePicker__calendarContainer.-top .Calendar {
  transform: translateY(-2.5em);
}

.Calendar.-noFocusOutline *:focus {
  outline: none !important;
}

.Calendar > :not(.Calendar__footer) button {
  font-family: inherit;
  background: transparent;
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
  outline: none;
}

.Calendar__header {
  display: flex;
  color: var(--cl-color-black);
  padding: 2em 2.9em;
  align-items: center;
  overflow: hidden;
}

.Calendar__monthArrowWrapper {
  line-height: 0;
  font-size: 1em;
  padding: 3px;
  position: relative;
  border: none;
  z-index: 1;
  opacity: 1;
  transition: 0.2s;
}

.Calendar__monthArrowWrapper:focus {
  outline: 1px dashed rgba(0, 0, 0, 0.4);
  outline-offset: 2px;
}

.Calendar__monthArrowWrapper:disabled,
.Calendar__monthArrowWrapper.-hidden {
  opacity: 0;
  pointer-events: none;
}

.Calendar__monthArrowWrapper.-left {
  transform: rotate(90deg);
}
.Calendar.-rtl .Calendar__monthArrowWrapper.-left {
  transform: rotate(-90deg);
}

.Calendar__monthArrowWrapper.-right {
  transform: rotate(-90deg);
}
.Calendar.-rtl .Calendar__monthArrowWrapper.-right {
  transform: rotate(90deg);
}

.Calendar__monthArrowWrapper:active .Calendar__monthArrow {
  transform: scale(0.7);
}

.Calendar__monthArrow {
  border-radius: 50%;
  transition: var(--animation-duration) transform;
  pointer-events: none;
  background-repeat: no-repeat;
  display: block;
  width: 1.7em;
  height: 1.7em;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'%3E%3Cg class='nc-icon-wrapper' fill='%23000000'%3E%3Cdefs stroke='none'%3E%3C/defs%3E%3Cpath class='cls-1' d='M12 23.25V.75' fill='none' stroke='%23000000' stroke-linecap='round' stroke-linejoin='round' stroke-width='1.5px'%3E%3C/path%3E%3Cpath class='cls-2' d='M22.5 11.25L12 .75 1.5 11.25' fill='none' stroke='%23000000' stroke-linecap='round' stroke-linejoin='round' stroke-width='1.5px' fill-rule='evenodd'%3E%3C/path%3E%3C/g%3E%3C/svg%3E");
  background-size: 100% 100%;
}

.Calendar__monthYearContainer {
  flex: 1 1;
  position: relative;
}

.Calendar__monthYear {
  font-size: 1.6em;
  font-weight: 500;
  display: flex;
  align-items: center;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 50%;
  will-change: transform, opacity;
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
  transform: translateZ(0);
  transition: var(--animation-duration);
  line-height: 1;
}

.Calendar__monthYear.-hiddenNext {
  opacity: 0;
  transform: translateX(50%);
}

.Calendar.-rtl .Calendar__monthYear.-hiddenNext {
  transform: translateX(-150%);
}

.Calendar__monthYear.-hiddenPrevious {
  opacity: 0;
  transform: translateX(-150%);
}

.Calendar.-rtl .Calendar__monthYear.-hiddenPrevious {
  transform: translateX(50%);
}

.Calendar__monthYear.-shown {
  opacity: 1;
  margin-top: auto;
  margin-bottom: auto;
  transform: translateX(-50%);
}

.Calendar__monthYear.-shownAnimated {
  -webkit-animation: var(--animation-duration) fadeTextToCenter forwards;
          animation: var(--animation-duration) fadeTextToCenter forwards;
}

.Calendar__monthYear > * {
  padding: 0.2em 0.5em;
  border: 1px solid transparent;
  transition: var(--animation-duration);
  font-size: 1.05em;
  display: flex;
  justify-content: center;
  align-items: center;
  transform: translateX(0) scale(0.95);
  will-change: transform;
  border-radius: 5px;
}

.Calendar__monthYear:not(.-shown) > *,
.Calendar__monthYear > *.-hidden {
  cursor: default;
  pointer-events: none;
}

.Calendar__monthText {
  margin-left: -0.3em;
}
.Calendar__yearText:last-child {
  margin-right: -0.3em;
}

.Calendar__monthYear.-shown > *:hover,
.Calendar:not(.-noFocusOutline) .Calendar__monthYear.-shown > *:focus,
.Calendar__monthYear > *.-activeBackground {
  background: #f5f5f5;
}

.Calendar__monthText:hover {
  transform: translateX(-0.2em) scale(0.95);
}
.Calendar.-rtl .Calendar__monthText:hover {
  transform: translateX(0.2em) scale(0.95);
}

.Calendar__yearText:hover {
  transform: translateX(0.2em) scale(0.95);
}
.Calendar.-rtl .Calendar__yearText:hover {
  transform: translateX(-0.2em) scale(0.95);
}

.Calendar__monthYear .Calendar__yearText.-hidden {
  transform: translateX(50%);
  opacity: 0;
}

.Calendar.-rtl .Calendar__monthYear .Calendar__yearText.-hidden {
  transform: translateX(-50%);
}

.Calendar__monthYear .Calendar__monthText.-hidden {
  transform: translateX(-50%);
  opacity: 0;
}

.Calendar.-rtl .Calendar__monthYear .Calendar__monthText.-hidden {
  transform: translateX(50%);
}

.Calendar__monthYear:not(.-shown) > * {
  pointer-events: none;
}

.Calendar__monthSelectorAnimationWrapper,
.Calendar__yearSelectorAnimationWrapper {
  position: absolute;
  width: 100%;
  height: 80%;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.Calendar__monthSelectorWrapper {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.Calendar__monthSelector {
  padding: 0 2.5em;
  align-content: center;
  padding-bottom: 2em;
}

.Calendar__monthSelector,
.Calendar__yearSelector {
  display: flex;
  flex-wrap: wrap;
  position: relative;
  z-index: 2;
  background-color: #fff;
  transform: translateY(-150%);
  will-change: transform;
  transition: 0.6s;
  height: 100%;
}

.Calendar__yearSelectorWrapper {
  width: 100%;
  height: 100%;
}

.Calendar__yearSelectorWrapper::after,
.Calendar__yearSelectorWrapper::before {
  content: '';
  width: 100%;
  height: 5em;
  position: absolute;
  left: 0;
  opacity: 0;
  transition: 0.4s;
  transition-delay: 0.2s;
}

.Calendar__yearSelectorWrapper::after {
  background-image: linear-gradient(to bottom, #fff, #fff 10%, rgba(245, 245, 245, 0));
  top: -0.1em;
}

.Calendar__yearSelectorWrapper::before {
  background-image: linear-gradient(to top, #fff, #fff 10%, rgba(245, 245, 245, 0));
  bottom: 0;
}

.Calendar__yearSelectorWrapper.-faded::after,
.Calendar__yearSelectorWrapper.-faded::before {
  opacity: 1;
  z-index: 3;
}

.Calendar__yearSelector {
  align-content: flex-start;
  scrollbar-width: 0;
  overflow: scroll;
  position: relative;
  width: 100%;
  padding: 5em 2em;
  -ms-overflow-style: none;
}

.Calendar__yearSelector::-webkit-scrollbar {
  display: none;
}

.Calendar__yearSelectorItem {
  width: 25%;
  display: flex;
  justify-content: center;
}

.Calendar__yearSelectorItem:not(:nth-child(-n + 4)) {
  margin-top: 1.5em;
}

.Calendar__yearSelectorText {
  border: none;
  font-size: 1.4em;
  min-width: 85%;
  padding: 0.2em 0.5em;
  border-radius: 8.5px;
}

.Calendar__monthSelector.-open,
.Calendar__yearSelector.-open {
  transform: translateY(0);
}

.Calendar__yearSelectorText:focus,
.Calendar__monthSelectorItemText:focus {
  outline: 1px dashed rgba(0, 0, 0, 0.4);
  outline-offset: 2px;
}

.Calendar__monthSelectorItem {
  width: calc(100% / 3);
  display: flex;
  justify-content: center;
}

.Calendar__monthSelectorItem:not(:nth-child(-n + 3)) {
  margin-top: 2em;
}

.Calendar__monthSelectorItemText {
  border: none;
  padding: 0.4em 0.4em;
  border-radius: 8.5px;
  font-size: 1.3em;
  min-width: 70%;
  transition: 0.3s;
}

.Calendar__monthSelectorItem:not(.-active) .Calendar__monthSelectorItemText:not(:disabled):hover,
.Calendar__yearSelectorItem:not(.-active) .Calendar__yearSelectorText:not(:disabled):hover {
  background: #f5f5f5;
}

.Calendar__monthSelectorItemText:disabled,
.Calendar__yearSelectorText:disabled {
  opacity: 0.5;
  cursor: default;
}

.Calendar__monthSelectorItem.-active .Calendar__monthSelectorItemText,
.Calendar__yearSelectorItem.-active .Calendar__yearSelectorText {
  background-color: var(--cl-color-primary);
  color: #fff;
}

.Calendar__weekDays {
  display: flex;
  justify-content: space-between;
  color: var(--cl-color-disabled);
  font-size: 1.2em;
  margin-bottom: 0.7em;
  padding: 0 2.6em;
  position: relative;
}

.Calendar__weekDay {
  display: block;
  width: calc(100% / 7);
  text-align: center;
  text-decoration: none;
}

.Calendar__sectionWrapper {
  position: relative;
  min-height: 25.8em;
  overflow: hidden;
}

.Calendar__section {
  display: flex;
  flex-direction: column;
  padding: 0 3.2em;
  position: absolute;
  color: var(--cl-color-black);
  top: 0;
  padding-top: 0.5em;
  left: 0;
  width: 100%;
  will-change: transform, opacity;
  transform: translateZ(0);
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
  transition: var(--animation-duration);
}

.Calendar__section.-hiddenPrevious {
  opacity: 0.5;
  transform: translateX(-90%);
}

.Calendar.-rtl .Calendar__section.-hiddenPrevious {
  transform: translateX(90%);
}

.Calendar__section.-hiddenNext {
  opacity: 0.5;
  transform: translateX(90%);
}

.Calendar.-rtl .Calendar__section.-hiddenNext {
  transform: translateX(-90%);
}

.Calendar__section.-shown {
  opacity: 1;
  transform: translateX(0);
}

.Calendar__section.-shownAnimated {
  -webkit-animation: var(--animation-duration) FadeContentToCenter forwards;
          animation: var(--animation-duration) FadeContentToCenter forwards;
}

.Calendar__weekRow {
  display: flex;
  width: 100%;
}

.Calendar__day {
  display: block;
  width: calc(100% / 7);
  text-align: center;
  padding: calc(0.25em - 1px) 0;
  font-size: 1.6em;
  border-radius: 50%;
  transition: 0.2s;
  border: 1px solid transparent;
  margin-bottom: 0.3em;
  color: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.Calendar__day:focus {
  outline: 1px dashed rgba(0, 0, 0, 0.4);
  outline-offset: 2px;
}

.Calendar__day.-ltr {
  min-height: 2.6em;
  font-size: 1.45em;
}

.Calendar__day.-rtl {
  font-size: 1.55em;
  height: 2.45em;
}

.Calendar__day:not(.-blank):not(.-selectedStart):not(.-selectedEnd):not(.-selectedBetween):not(.-selected):hover {
  background: #eaeaea;
  border-radius: 50%;
  color: var(--cl-color-black);
  border-color: transparent;
}

.Calendar__day.-selected,
.Calendar__day.-selectedStart,
.Calendar__day.-selectedEnd {
  background: var(--cl-color-primary);
  color: #fff;
}

.Calendar__day.-ltr.-selectedStart {
  border-radius: 0;
  border-top-left-radius: 100em;
  border-bottom-left-radius: 100em;
}

.Calendar__day.-rtl.-selectedStart {
  border-radius: 0;
  border-top-right-radius: 100em;
  border-bottom-right-radius: 100em;
}

.Calendar__day.-selectedBetween {
  background: var(--cl-color-primary-light);
  color: var(--cl-color-primary);
  border-radius: 0;
}

.Calendar__day.-ltr.-selectedEnd {
  border-top-right-radius: 100em;
  border-bottom-right-radius: 100em;
}

.Calendar__day.-rtl.-selectedEnd {
  border-top-left-radius: 100em;
  border-bottom-left-radius: 100em;
}

.Calendar__day.-weekend:not(.-selected):not(.-blank):not(.-selectedStart):not(.-selectedEnd):not(.-selectedBetween) {
  color: var(--cl-color-error);
}

.Calendar__day.-weekend.-today:not(.-selectedStart):not(.-selectedEnd):not(.-selectedBetween)::after {
  background: var(--cl-color-error);
}

.Calendar__day.-disabled {
  color: var(--cl-color-disabled) !important;
  background: transparent !important;
  cursor: default !important;
}
.Calendar__day.-selected {
  border-radius: 50%;
}
.Calendar__day.-today:not(.-selectedStart):not(.-selectedEnd):not(.-selectedBetween) {
  font-weight: 600;
  color: var(--cl-color-black);
  color: #000;
  position: relative;
}

.Calendar__day.-today:not(.-selectedStart):not(.-selectedEnd):not(.-selectedBetween)::after {
  content: '';
  position: absolute;
  bottom: 0.2em;
  display: block;
  width: 0.6em;
  height: 1px;
  background: #000;
  left: 50%;
  opacity: 0.5;
  transform: translateX(-50%);
  transition: 0.2s;
}

.Calendar__day.-today:hover:not(.-selectedStart):not(.-selectedEnd):not(.-selectedBetween)::after {
  opacity: 0;
}

.Calendar__day.-blank {
  color: transparent;
  cursor: default;
  pointer-events: none;
}

.Calendar__footer {
  position: relative;
  z-index: 1;
}

@-webkit-keyframes fadeCalendar {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes fadeCalendar {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@-webkit-keyframes fadeArrowFlipped {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
    transform: translateY(0) rotate(180deg);
  }
}

@keyframes fadeArrowFlipped {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
    transform: translateY(0) rotate(180deg);
  }
}

@-webkit-keyframes fadeTextToCenter {
  to {
    opacity: 1;
    transform: translateX(-50%);
  }
}

@keyframes fadeTextToCenter {
  to {
    opacity: 1;
    transform: translateX(-50%);
  }
}

@-webkit-keyframes FadeContentToCenter {
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes FadeContentToCenter {
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

.emoji-mart,
.emoji-mart * {
  box-sizing: border-box;
  line-height: 1.15;
}

.emoji-mart {
  font-family: -apple-system, BlinkMacSystemFont, "Helvetica Neue", sans-serif;
  font-size: 16px;
  display: inline-block;
  color: #222427;
  border: 1px solid #d9d9d9;
  border-radius: 5px;
  background: #fff;
}

.emoji-mart .emoji-mart-emoji {
  padding: 6px;
}

.emoji-mart-bar {
  border: 0 solid #d9d9d9;
}
.emoji-mart-bar:first-child {
  border-bottom-width: 1px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}
.emoji-mart-bar:last-child {
  border-top-width: 1px;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}

.emoji-mart-anchors {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0 6px;
  line-height: 0;
}

.emoji-mart-anchor {
  position: relative;
  display: block;
  flex: 1 1 auto;
  color: #858585;
  text-align: center;
  padding: 12px 4px;
  overflow: hidden;
  transition: color .1s ease-out;
  margin: 0;
  box-shadow: none;
  background: none;
  border: none;
}
.emoji-mart-anchor:focus { outline: 0 }
.emoji-mart-anchor:hover,
.emoji-mart-anchor:focus,
.emoji-mart-anchor-selected {
  color: #464646;
}

.emoji-mart-anchor-selected .emoji-mart-anchor-bar {
  bottom: 0;
}

.emoji-mart-anchor-bar {
  position: absolute;
  bottom: -3px; left: 0;
  width: 100%; height: 3px;
  background-color: #464646;
}

.emoji-mart-anchors i {
  display: inline-block;
  width: 100%;
  max-width: 22px;
}

.emoji-mart-anchors svg,
.emoji-mart-anchors img {
  fill: currentColor;
  height: 18px;
  width: 18px;
}

.emoji-mart-scroll {
  overflow-y: scroll;
  overflow-x: hidden;
  height: 270px;
  padding: 0 6px 6px 6px;
  will-change: transform; /* avoids "repaints on scroll" in mobile Chrome */
}

.emoji-mart-search {
  margin-top: 6px;
  padding: 0 6px;
  position: relative;
}

.emoji-mart-search input {
  font-size: 16px;
  display: block;
  width: 100%;
  padding: 5px 25px 6px 10px;
  border-radius: 5px;
  border: 1px solid #d9d9d9;
  outline: 0;
}

.emoji-mart-search input,
.emoji-mart-search input::-webkit-search-decoration,
.emoji-mart-search input::-webkit-search-cancel-button,
.emoji-mart-search input::-webkit-search-results-button,
.emoji-mart-search input::-webkit-search-results-decoration {
  /* remove webkit/blink styles for <input type="search">
   * via https://stackoverflow.com/a/9422689 */
  -webkit-appearance: none;
}

.emoji-mart-search-icon {
  position: absolute;
  top: 7px;
  right: 11px;
  z-index: 2;
  padding: 2px 5px 1px;
  border: none;
  background: none;
}

.emoji-mart-category .emoji-mart-emoji span {
  z-index: 1;
  position: relative;
  text-align: center;
  cursor: default;
}

.emoji-mart-category .emoji-mart-emoji:hover:before {
  z-index: 0;
  content: "";
  position: absolute;
  top: 0; left: 0;
  width: 100%; height: 100%;
  background-color: #f4f4f4;
  border-radius: 100%;
}

.emoji-mart-category-label {
  z-index: 2;
  position: relative;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
}

.emoji-mart-category-label span {
  display: block;
  width: 100%;
  font-weight: 500;
  padding: 5px 6px;
  background-color: #fff;
  background-color: rgba(255, 255, 255, .95);
}

.emoji-mart-category-list {
  margin: 0;
  padding: 0;
}

.emoji-mart-category-list li {
  list-style: none;
  margin: 0;
  padding: 0;
  display: inline-block;
}

.emoji-mart-emoji {
  position: relative;
  display: inline-block;
  font-size: 0;
  margin: 0;
  padding: 0;
  border: none;
  background: none;
  box-shadow: none;
}

.emoji-mart-emoji-native {
  font-family: "Segoe UI Emoji", "Segoe UI Symbol", "Segoe UI", "Apple Color Emoji", "Twemoji Mozilla", "Noto Color Emoji", "Android Emoji";
}

.emoji-mart-no-results {
  font-size: 14px;
  text-align: center;
  padding-top: 70px;
  color: #858585;
}
.emoji-mart-no-results-img {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 50%;
}
.emoji-mart-no-results .emoji-mart-category-label {
  display: none;
}
.emoji-mart-no-results .emoji-mart-no-results-label {
  margin-top: .2em;
}
.emoji-mart-no-results .emoji-mart-emoji:hover:before {
  content: none;
}

.emoji-mart-preview {
  position: relative;
  height: 70px;
}

.emoji-mart-preview-emoji,
.emoji-mart-preview-data,
.emoji-mart-preview-skins {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.emoji-mart-preview-emoji {
  left: 12px;
}

.emoji-mart-preview-data {
  left: 68px; right: 12px;
  word-break: break-all;
}

.emoji-mart-preview-skins {
  right: 30px;
  text-align: right;
}

.emoji-mart-preview-skins.custom {
  right: 10px;
  text-align: right;
}

.emoji-mart-preview-name {
  font-size: 14px;
}

.emoji-mart-preview-shortname {
  font-size: 12px;
  color: #888;
}
.emoji-mart-preview-shortname + .emoji-mart-preview-shortname,
.emoji-mart-preview-shortname + .emoji-mart-preview-emoticon,
.emoji-mart-preview-emoticon + .emoji-mart-preview-emoticon {
  margin-left: .5em;
}

.emoji-mart-preview-emoticon {
  font-size: 11px;
  color: #bbb;
}

.emoji-mart-title span {
  display: inline-block;
  vertical-align: middle;
}

.emoji-mart-title .emoji-mart-emoji {
  padding: 0;
}

.emoji-mart-title-label {
  color: #999A9C;
  font-size: 26px;
  font-weight: 300;
}

.emoji-mart-skin-swatches {
  font-size: 0;
  padding: 2px 0;
  border: 1px solid #d9d9d9;
  border-radius: 12px;
  background-color: #fff;
}

.emoji-mart-skin-swatches.custom {
  font-size: 0;
  border: none;
  background-color: #fff;
}

.emoji-mart-skin-swatches.opened .emoji-mart-skin-swatch {
  width: 16px;
  padding: 0 2px;
}

.emoji-mart-skin-swatches.opened .emoji-mart-skin-swatch.selected:after {
  opacity: .75;
}

.emoji-mart-skin-swatch {
  display: inline-block;
  width: 0;
  vertical-align: middle;
  transition-property: width, padding;
  transition-duration: .125s;
  transition-timing-function: ease-out;
}

.emoji-mart-skin-swatch:nth-child(1) { transition-delay: 0s }
.emoji-mart-skin-swatch:nth-child(2) { transition-delay: .03s }
.emoji-mart-skin-swatch:nth-child(3) { transition-delay: .06s }
.emoji-mart-skin-swatch:nth-child(4) { transition-delay: .09s }
.emoji-mart-skin-swatch:nth-child(5) { transition-delay: .12s }
.emoji-mart-skin-swatch:nth-child(6) { transition-delay: .15s }

.emoji-mart-skin-swatch.selected {
  position: relative;
  width: 16px;
  padding: 0 2px;
}

.emoji-mart-skin-swatch.selected:after {
  content: "";
  position: absolute;
  top: 50%; left: 50%;
  width: 4px; height: 4px;
  margin: -2px 0 0 -2px;
  background-color: #fff;
  border-radius: 100%;
  pointer-events: none;
  opacity: 0;
  transition: opacity .2s ease-out;
}

.emoji-mart-skin-swatch.custom {
  display: inline-block;
  width: 0;
  height: 38px;
  overflow: hidden;
  vertical-align: middle;
  transition-property: width, height;
  transition-duration: .125s;
  transition-timing-function: ease-out;
  cursor: default;
}

.emoji-mart-skin-swatch.custom.selected {
  position: relative;
  width: 36px;
  height: 38px;
  padding: 0 2px 0 0;
}

.emoji-mart-skin-swatch.custom.selected:after {
  content: "";
  width: 0;
  height: 0;
}

.emoji-mart-skin-swatches.custom .emoji-mart-skin-swatch.custom:hover {
  background-color: #f4f4f4;
  border-radius: 10%;
}

.emoji-mart-skin-swatches.custom.opened .emoji-mart-skin-swatch.custom {
  width: 36px;
  height: 38px;
  padding: 0 2px 0 0;
}

.emoji-mart-skin-swatches.custom.opened .emoji-mart-skin-swatch.custom.selected:after {
  opacity: .75;
}

.emoji-mart-skin-text.opened {
  display: inline-block;
  vertical-align: middle;
  text-align: left;
  color: #888;
  font-size: 11px;
  padding: 5px 2px;
  width: 95px;
  height: 40px;
  border-radius: 10%;
  background-color: #fff;
}

.emoji-mart-skin {
  display: inline-block;
  width: 100%;
  padding-top: 100%;
  max-width: 12px;
  border-radius: 100%;
}

.emoji-mart-skin-tone-1 { background-color: #ffc93a }
.emoji-mart-skin-tone-2 { background-color: #fadcbc }
.emoji-mart-skin-tone-3 { background-color: #e0bb95 }
.emoji-mart-skin-tone-4 { background-color: #bf8f68 }
.emoji-mart-skin-tone-5 { background-color: #9b643d }
.emoji-mart-skin-tone-6 { background-color: #594539 }

/* For screenreaders only, via https://stackoverflow.com/a/19758620 */
.emoji-mart-sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
}

/*
 * Dark mode styles
 */

.emoji-mart-dark {
  color: #fff;
  border-color: #555453;
  background-color: #222;
}

.emoji-mart-dark .emoji-mart-bar {
  border-color: #555453;
}

.emoji-mart-dark .emoji-mart-search input {
  color: #fff;
  border-color: #555453;
  background-color: #2f2f2f;
}

.emoji-mart-dark .emoji-mart-search-icon svg {
  fill: #fff;
}

.emoji-mart-dark .emoji-mart-category .emoji-mart-emoji:hover:before {
  background-color: #444;
}

.emoji-mart-dark .emoji-mart-category-label span {
  background-color: #222;
  color: #fff;
}

.emoji-mart-dark .emoji-mart-skin-swatches {
  border-color: #555453;
  background-color: #222;
}

.emoji-mart-dark .emoji-mart-anchor:hover,
.emoji-mart-dark .emoji-mart-anchor:focus,
.emoji-mart-dark .emoji-mart-anchor-selected {
  color: #bfbfbf;
}

@charset "UTF-8";
@font-face {
  font-family: 'Notification';
  src: url(/static/media/notification.c0d3c94c.eot);
  src: url(/static/media/notification.c0d3c94c.eot?#iefixs3g3t9) format("embedded-opentype"), url(/static/media/notification.651771e1.woff) format("woff"), url(/static/media/notification.0b4ac1dc.ttf) format("truetype"), url(/static/media/notification.5bee74ca.svg#notification) format("svg");
  font-weight: normal;
  font-style: normal;
}

.notification-container {
  box-sizing: border-box;
  position: fixed;
  top: 0;
  right: 0;
  z-index: 999999;
  width: 320px;
  padding: 0px 15px;
  max-height: calc(100% - 30px);
  overflow-x: hidden;
  overflow-y: auto;
}

.notification {
  box-sizing: border-box;
  padding: 15px 15px 15px 58px;
  border-radius: 2px;
  color: #fff;
  background-color: #ccc;
  box-shadow: 0 0 12px #999;
  cursor: pointer;
  font-size: 1em;
  line-height: 1.2em;
  position: relative;
  opacity: 0.9;
  margin-top: 15px;
}

.notification .title {
  font-size: 1em;
  line-height: 1.2em;
  font-weight: bold;
  margin: 0 0 5px 0;
}

.notification:hover, .notification:focus {
  opacity: 1;
}

.notification-enter {
  visibility: hidden;
  transform: translate3d(100%, 0, 0);
}

.notification-enter.notification-enter-active {
  visibility: visible;
  transform: translate3d(0, 0, 0);
  transition: all 0.4s;
}

.notification-leave {
  visibility: visible;
  transform: translate3d(0, 0, 0);
}

.notification-leave.notification-leave-active {
  visibility: hidden;
  transform: translate3d(100%, 0, 0);
  transition: all 0.4s;
}

.notification:before {
  position: absolute;
  top: 50%;
  left: 15px;
  margin-top: -14px;
  display: block;
  font-family: 'Notification';
  width: 28px;
  height: 28px;
  font-size: 28px;
  text-align: center;
  line-height: 28px;
}

.notification-info {
  background-color: #2f96b4;
}

.notification-info:before {
  content: "";
}

.notification-success {
  background-color: #51a351;
}

.notification-success:before {
  content: "";
}

.notification-warning {
  background-color: #f89406;
}

.notification-warning:before {
  content: "";
}

.notification-error {
  background-color: #bd362f;
}

.notification-error:before {
  content: "";
}

